import AstroGun from "@/components/astro-gun";
import AstroQuestion from "@/components/astro-question";
import { FormError, FormSuccess } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { Transition } from "@/components/transition";
import { capitalizeFirst } from "@/helpers/format";
import { assignPathWith } from "@/helpers/navigation";
import { relativeDate } from "@/helpers/time";
import { dialogStyle, tableContainerSx } from "@/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import { useTranslation } from "react-i18next";

const CREATE_EVENT_FROM_DASHBOARD = gql`
  mutation CreateEventFromDashboard(
    $action: Action!
    $memberID: UUID!
    $zoneID: UUID!
  ) {
    CreateEventFromDashboard(
      action: $action
      memberID: $memberID
      zoneID: $zoneID
    ) {
      action
    }
  }
`;

const GET_MYSELF_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      zones {
        id
        name
        kind
        geofencing
        qrcodeSignedURL
      }
      zoneSetting {
        id
        qrcodeAccessLocationMode
      }
    }
  }
`;

export function Name({ member }) {
  if (member.firstName === null && member.lastName === null) return "-";
  const fullName = `${member.firstName} ${member.lastName}`;

  if (member.accessRevokedAt) {
    return <div style={{ textDecoration: "line-through" }}>{fullName}</div>;
  }

  return fullName;
}

export function ChipRole({ role }) {
  const { t } = useTranslation("organization");

  switch (role) {
    case "ADMIN":
      return <Chip label={t("members.list.admin-role")} color="secondary" />;
    case "VIEWER":
      return <Chip label={t("members.list.viewer-role")} color="primary" />;
    case "USER":
      return <Chip label={t("members.list.user-role")} />;
    default:
      return <></>;
  }
}

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const SessionSettingCell = ({ member }: { member: Member }) => {
  const { t } = useTranslation("organization");

  if (member.sessionSetting?.id) {
    return (
      <Tooltip
        title={t("members.list.session-setting-on-tooltip")}
        placement="top"
      >
        <Chip
          icon={<CheckCircleOutlineIcon color="primary" />}
          label={t("members.list.session-setting-on")}
        />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={t("members.list.session-setting-off-tooltip")}
        placement="top"
      >
        <Chip
          icon={<HighlightOffIcon color="info" />}
          label={t("members.list.session-setting-off")}
        />
      </Tooltip>
    );
  }
};

export function List({ members }) {
  const { i18n, t } = useTranslation("organization");

  if (members == null || members.length === 0) {
    return (
      <Stack alignItems="center" textAlign="center">
        <Grid container alignContent="center">
          <Grid item xs={12} lg={4} sx={{ mb: 3 }} margin="auto">
            <AstroQuestion />
            <Typography>{t("members.list.nothing-to-show")}</Typography>
          </Grid>
        </Grid>
      </Stack>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      valueGetter: (_, row) => {
        return `${row.firstName} ${row.lastName}`;
      },
      flex: 1,
      headerName: t("members.list.name"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <SensitiveData fakeType="name">
            <Name member={params.row} />
          </SensitiveData>
        );
      },
    },
    {
      field: "role",
      flex: 1,
      headerName: t("members.list.role"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ChipRole role={params.row.role} />;
      },
    },
    {
      field: "email",
      flex: 1,
      headerName: t("members.list.email"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <SensitiveData fakeType="email">{params.row.email}</SensitiveData>
        );
      },
    },
    {
      field: "phone",
      flex: 1,
      headerName: t("members.list.phone"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <SensitiveData fakeType="phone">{params.row.phone}</SensitiveData>
        );
      },
    },
    {
      field: "registration-date",
      flex: 1,
      headerName: t("members.list.registration-date"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <>{capitalizeFirst(relativeDate(params.row.createdAt, i18n))}</>;
      },
      valueGetter: (_, row) => {
        return relativeDate(row.createdAt, i18n);
      },
    },
    {
      field: "last-event",
      flex: 1,
      headerName: t("members.list.last-event"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <>
            {params.row.lastEvent?.createdAt
              ? capitalizeFirst(
                  relativeDate(params.row.lastEvent?.createdAt, i18n)
                )
              : "-"}
          </>
        );
      },
      valueGetter: (_, row) => {
        if (!row.lastEvent.createdAt) return "";
        return relativeDate(row.lastEvent?.createdAt, i18n);
      },
    },
    {
      field: "session-setting",
      flex: 1,
      headerName: t("members.list.session-setting"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <SessionSettingCell member={params.row} />;
      },
    },
    {
      field: "actions",
      flex: 0.6,
      headerName: "",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ActionMore member={params.row} />;
      },
    },
  ];

  const rows = members;
  return <GridWithSearch columns={columns} rows={rows} i18n={i18n} />;
}

import { GridWithSearch } from "@/components/data-table";
import { SensitiveData } from "@/components/sensitive-data";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { Member, Zone } from "@/interfaces";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export function ActionMore({ member }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label={t("members.list.team-members-metrics-button")}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuActionCheck member={member} handleClose={handleClose} />
        <Divider />
        <MenuActionMetrics member={member} disabled={!member.lastEvent.id} />
        <MenuActionDevices member={member} />
        <MenuActionEdit member={member} />
      </Menu>
    </React.Fragment>
  );
}

export function MenuActionCheck({ member, handleClose }) {
  const { t } = useTranslation("organization");
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <ManualActionDialog open={open} setOpen={setOpen} member={member} />
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.CREATE,
          })
        }
        onClick={() => {
          handleClose();
          setOpen(true);
        }}
      >
        <ListItemIcon>
          <AdsClickIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("members.list.team-members-checkin-button")}
        </ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export function ZoneSelector({ zones, zone, setZone }) {
  const handleChange = (event) => {
    const selectedZone = zones.find((z) => z.id === event.target.value);
    setZone(selectedZone);
  };

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mb: 2 }}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="zones"
          name="zones"
          value={zone.id}
          onChange={handleChange}
        >
          {zones.map((currentZone) => (
            <FormControlLabel
              key={currentZone.id}
              value={currentZone.id}
              control={<Radio />}
              label={currentZone.name} // Assuming each zone has a name property
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export function ManualActionDialog({ open, setOpen, member }) {
  const { t, i18n } = useTranslation("organization");
  const [createEvent, { data, loading, error }] = useMutation(
    CREATE_EVENT_FROM_DASHBOARD
  );
  const myOrganization = useQuery(GET_MYSELF_ORGANIZATION, {
    skip: !open,
  });
  const [zone, setZone] = React.useState<Zone>();

  let zones: Zone[] = [];
  if (myOrganization.data) {
    zones = myOrganization.data.MyOrganization.zones;
  }

  React.useEffect(() => {
    // we select the first zone as default for checkin/checkout
    if (zones.length !== 0) setZone(zones[0]);
  }, [zones]);

  const lastEvent = member.lastEvent;

  let denomination: string;
  if (member.FirstName) {
    denomination = `${member.FirstName} ${member.LastName}`;
  } else {
    denomination = member.email || member.phone;
  }

  const SelectZone = ({ zones, zone, setZone }) => {
    if (!zone) return;

    return (
      <React.Fragment>
        <DialogContentText sx={{ fontWeight: "bold", color: "black", mt: 2 }}>
          {t("members.create-event-question-zone")}
        </DialogContentText>
        <DialogContentText sx={{ fontWeight: "bold", color: "black", mt: 2 }}>
          <ZoneSelector zones={zones} zone={zone} setZone={setZone} />
        </DialogContentText>
      </React.Fragment>
    );
  };

  const HasCheckedIn = () => {
    const handleCheckout = () => {
      if (!zone) return;
      createEvent({
        variables: {
          memberID: member.id,
          zoneID: zone.id,
          action: "CHECKOUT",
        },
      });
    };

    return (
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {data ? (
            <FormSuccess
              message={t("members.create-event-success")}
              sx={{ mb: 3 }}
            />
          ) : (
            <></>
          )}
        </DialogContentText>
        {zones.length > 1 ? (
          <SelectZone zones={zones} zone={zone} setZone={setZone} />
        ) : (
          <></>
        )}
        <DialogContentText>
          <FormError error={error} sx={{ mb: 1 }} />
          <LoadingButton
            loading={loading || myOrganization.loading}
            disabled={loading || data}
            onClick={handleCheckout}
            color="secondary"
            text={t("members.create-event-confirm-checkout")}
            fullWidth
          />
        </DialogContentText>
      </DialogContent>
    );
  };

  const HasCheckedOut = () => {
    const handleCheckin = () => {
      if (!zone) return;
      createEvent({
        variables: {
          memberID: member.id,
          zoneID: zone.id,
          action: "CHECKIN",
        },
      });
    };

    return (
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {data ? (
            <FormSuccess
              message={t("members.create-event-success")}
              sx={{ mb: 3 }}
            />
          ) : (
            <></>
          )}
        </DialogContentText>
        {zones.length > 1 ? (
          <SelectZone zones={zones} zone={zone} setZone={setZone} />
        ) : (
          <></>
        )}
        <DialogContentText>
          <FormError error={error} sx={{ mb: 1 }} />
          <LoadingButton
            loading={loading}
            disabled={loading || data}
            onClick={handleCheckin}
            text={t("members.create-event-confirm-checkin")}
            fullWidth
          />
        </DialogContentText>
      </DialogContent>
    );
  };

  const hasCheckedIn = lastEvent?.action === "CHECKIN";
  const lastEventDate = member.lastEvent.createdAt
    ? relativeDate(member.lastEvent.createdAt, i18n)
    : "";

  const introText = hasCheckedIn ? (
    <>
      <strong>{denomination}</strong>{" "}
      {t("members.create-event-to-checkout", { lastEventDate })}
    </>
  ) : (
    <>
      <strong>{denomination}</strong>{" "}
      {t("members.create-event-hasnt-checked-in")}
    </>
  );

  return (
    <Dialog
      open={open}
      sx={dialogStyle}
      TransitionComponent={Transition}
      onClose={() => {
        setOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {t("members.create-event-title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {introText}
        </DialogContentText>
        <Stack alignItems="center" textAlign="center">
          <Grid item xs={2} lg={4} sx={{ mt: 2 }}>
            <AstroGun />
          </Grid>
        </Stack>
      </DialogContent>
      {hasCheckedIn ? <HasCheckedIn /> : <HasCheckedOut />}
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("members.create-event-close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function MenuActionMetrics({
  member,
  searchDate = "",
  disabled = false,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("organization");

  let searchParams = "";
  // If it's custom, for now we just don't move it over
  // Because it'll not work since we don't have the exact dates
  if (searchDate && searchDate !== "custom") {
    searchParams = `?date_selector=${searchDate}`;
  }

  return (
    <React.Fragment>
      <MenuItem
        disabled={disabled}
        onClick={() => {
          assignPathWith(
            navigate,
            `/organization/dashboard/members/${member.id}/metrics${searchParams}`
          );
        }}
      >
        <ListItemIcon>
          <BarChartIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("members.list.team-members-metrics-button")}
        </ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import { useNavigate } from "react-router-dom";

export function MenuActionDevices({ member, searchDate = "" }) {
  const navigate = useNavigate();
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <MenuItem
        // lastKnownIP being present typically means there are devices registered
        disabled={!member.lastKnownIP}
        onClick={() => {
          assignPathWith(
            navigate,
            `/organization/dashboard/members/${member.id}/devices`
          );
        }}
      >
        <ListItemIcon>
          <AddToHomeScreenIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("members.list.team-members-devices-button")}
        </ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export function MenuActionEdit({ member }) {
  const { t } = useTranslation("organization");
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          assignPathWith(
            navigate,
            `/organization/dashboard/members/${member.id}/edit`
          );
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("members.list.team-members-edit-button")}
        </ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export default function MembersList({ members }) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List members={members} />
      </TableContainer>
    </React.Fragment>
  );
}
