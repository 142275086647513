import AstroQuestion from "@/components/astro-question";
import { GridWithSearch } from "@/components/data-table";
import { QrcodeImage } from "@/components/qr-code";
import { downloadImage } from "@/helpers";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { rawURLAssign } from "@/helpers/navigation";
import { Member, Zone } from "@/interfaces";
import { tableContainerSx } from "@/theme";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

export function List({ zones }) {
  const { i18n, t } = useTranslation("organization");

  if (zones == null || zones.length === 0) {
    return (
      <Stack alignItems="center" textAlign="center">
        <Grid container alignContent="center">
          <Grid item xs={12} lg={4} sx={{ mb: 3 }} margin="auto">
            <AstroQuestion />
            <Typography>{t("zones.list.nothing-to-show")}</Typography>
          </Grid>
        </Grid>
      </Stack>
    );
  }

  const ZoneGeofencingCell = ({ zone }: { zone: Zone }) => {
    if (zone.geofencing) {
      return (
        <Tooltip title={t("zones.geofencing-on-tooltip")} placement="top">
          <Chip
            icon={<FilterTiltShiftIcon color="primary" />}
            label={t("zones.geofencing-on")}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t("zones.geofencing-off-tooltip")} placement="top">
          <Chip
            icon={<FilterTiltShiftIcon color="error" />}
            label={t("zones.geofencing-off")}
          />
        </Tooltip>
      );
    }
  };

  const ZoneKindCell = ({ zone }: { zone: Zone }) => {
    if (zone.kind === "FIXED") {
      return (
        <Tooltip title={t("zones.kind-fixed-tooltip")} placement="top">
          <Chip icon={<LocationOnIcon />} label={t("zones.kind-fixed")} />
        </Tooltip>
      );
    } else if (zone.kind === "MOVING") {
      return (
        <Tooltip title={t("zones.kind-moving-tooltip")} placement="top">
          <Chip
            icon={<DirectionsCarFilledIcon />}
            label={t("zones.kind-moving")}
          />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "qrcode",
      flex: 1,
      headerName: t("zones.list.qrcode"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <QrcodeImage
            image={params.row.qrcodeSignedURL}
            clickToDownload={false}
            mini={true}
          />
        );
      },
    },
    {
      field: "name",
      flex: 1,
      headerName: t("members.list.name"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return params.row.name;
      },
      valueGetter: (_, row) => {
        return row.name;
      },
    },
    {
      field: "geofencing",
      flex: 1,
      headerName: t("zones.list.geofencing"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ZoneGeofencingCell zone={params.row} />;
      },
      valueGetter: (_, row) => {
        return row.geofencing ? "on" : "off";
      },
    },
    {
      field: "kind",
      flex: 1,
      headerName: t("zones.list.kind"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ZoneKindCell zone={params.row} />;
      },
      valueGetter: (_, row) => {
        return row.kind;
      },
    },
    {
      field: "actions",
      flex: 0.6,
      headerName: "",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ActionMore zone={params.row} />;
      },
    },
  ];

  const rows = zones;
  return (
    <GridWithSearch columns={columns} rows={rows} i18n={i18n} rowHeight={80} />
  );
}

export function ActionMore({ zone }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (zone: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(zone.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label={t("zones.more-button")}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuAction zone={zone} handleClose={handleClose} />
      </Menu>
    </React.Fragment>
  );
}

export function MenuAction({ zone, handleClose }) {
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          handleClose();
          downloadImage(zone.qrcodeSignedURL);
        }}
      >
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{t("zones.download-qrcode")}</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          handleClose();
          rawURLAssign(`/organization/dashboard/zones/${zone.id}/edit`);
        }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>{t("zones.edit-qrcode")}</ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export default function ZonesList({ zones }) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List zones={zones} />
      </TableContainer>
    </React.Fragment>
  );
}
