import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";

import PartialError from "@/components/partial-error";
import PartialLoading from "@/components/partial-loading";
import { gql, useQuery } from "@apollo/client";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import {
  getDateAndTime,
  getDurationFromString,
  iso8601DurationToSeconds,
} from "@/helpers/time";
import { useTranslation } from "react-i18next";

const LIST_SESSIONS_DAILY_METRICS = gql`
  query ListSessionsDailyMetrics(
    $startDate: Time!
    $endDate: Time!
    $memberId: UUID!
    $zoneId: UUID
  ) {
    ListSessionsDailyMetrics(
      startDate: $startDate
      endDate: $endDate
      memberId: $memberId
      zoneId: $zoneId
    ) {
      totalDuration
      startedAt
      alerts {
        id
        alertType
        metadata
      }
      comments {
        id
        content
      }
      endedAt
    }
  }
`;

export default function Timesheet({
  dateRange,
  memberId,
  zoneId,
  setDailyMetrics = (arg) => {},
}) {
  const variables = {
    startDate: dateRange.start,
    endDate: dateRange.end,
    memberId,
  };

  if (zoneId) {
    variables["zoneId"] = zoneId;
  }

  const { data, error, loading } = useQuery(LIST_SESSIONS_DAILY_METRICS, {
    variables,
  });

  useEffect(() => {
    if (!data?.ListSessionsDailyMetrics) return;
    const dailyMetrics = data.ListSessionsDailyMetrics;

    if (dailyMetrics.length > 0) setDailyMetrics(dailyMetrics);
  }, [data]);

  if (loading) return <PartialLoading />;
  if (error) return <PartialError />;

  return (
    <React.Fragment>
      <List dailyMetrics={data.ListSessionsDailyMetrics} />
    </React.Fragment>
  );
}

export function AlertCell({ alerts }) {
  const { t } = useTranslation("organization");
  if (!alerts || alerts.length === 0) return <div>-</div>;

  const ShowAlerts: React.JSX.Element[] = [];
  let idx = 0;
  alerts.forEach((alert) => {
    let title: string = "";
    let icon: React.ReactElement = <div></div>;

    if (alert.alertType === "system-checkout") {
      title = t("members.metrics.timesheet-system-checkout-alert");
      icon = <AccessAlarmIcon color="error" />;
    } else if (alert.alertType === "far-away-checking") {
      title = t("members.metrics.timesheet-far-away-checking-alert");
      icon = <MyLocationIcon color="error" />;
    } else {
      title = t("members.metrics.timesheet-unknown-alert");
      icon = <QuestionMarkIcon color="error" />;
    }

    ShowAlerts.push(
      <Tooltip key={idx} title={title} placement="top">
        {icon}
      </Tooltip>
    );
    idx++;
  });

  return <React.Fragment>{ShowAlerts}</React.Fragment>;
}

import { GridWithSearch } from "@/components/data-table";
import { inlineAlerts, inlineComments } from "@/helpers/csv";
import i18n from "@/i18n";
import { Member } from "@/interfaces";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export function CommentCell({ comments }) {
  if (!comments || comments.length === 0) return <div>-</div>;

  const ShowComments: React.JSX.Element[] = [];
  let idx = 0;
  comments.forEach((comment) => {
    ShowComments.push(
      <Tooltip key={idx} title={comment.content} placement="top">
        <EditNoteIcon color="primary" />
      </Tooltip>
    );
    idx++;
  });

  return <React.Fragment>{ShowComments}</React.Fragment>;
}

export function List({ dailyMetrics }) {
  const { t } = useTranslation(["organization", "misc"]);

  const columns: GridColDef[] = [
    {
      field: "time-worked",
      valueGetter: (_, row) => {
        return iso8601DurationToSeconds(row.totalDuration);
      },
      flex: 1,
      headerName: t("members.metrics.timesheet-time-worked"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return getDurationFromString(params.row.totalDuration, false, t);
      },
    },

    {
      field: "started-at",
      valueGetter: (_, row) => {
        return row.startedAt;
      },
      flex: 1,
      headerName: t("members.metrics.timesheet-started-at"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return getDateAndTime(params.row.startedAt);
      },
    },
    {
      field: "ended-at",
      valueGetter: (_, row) => {
        return row.endedAt;
      },
      flex: 1,
      headerName: t("members.metrics.timesheet-ended-at"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return getDateAndTime(params.row.endedAt);
      },
    },
    {
      field: "alerts",
      valueGetter: (_, row) => {
        if (!row.comments?.length) {
          return "0";
        }
        return `${row.alerts?.length}: ${inlineAlerts(t, row.alerts)}`;
      },
      flex: 1,
      headerName: t("members.metrics.timesheet-alerts"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <AlertCell alerts={params.row.alerts} />;
      },
      valueParser: (value) => {
        inlineAlerts(t, value.alerts);
      },
    },
    {
      field: "comments",
      valueGetter: (_, row) => {
        if (!row.comments?.length) {
          return "0";
        }
        return `${row.comments.length}: ${inlineComments(row.comments)}`;
      },
      flex: 1,
      headerName: t("members.metrics.timesheet-comments"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <CommentCell comments={params.row.comments} />;
      },
    },
  ];

  const rows = dailyMetrics;

  // In the case of daily metrics we do not returns the IDs
  // Because sometimes it's a mix of multiple daily metrics that compose the result
  // So we'll have to create them on the fly
  const rowsWithIDs = rows.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  return (
    <GridWithSearch
      columns={columns}
      rows={rowsWithIDs}
      i18n={i18n}
      initialState={{
        sorting: {
          sortModel: [{ field: "started-at", sort: "asc" }],
        },
      }}
    />
  );
}
