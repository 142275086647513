import { FormError, FormErrorComponent, FormWarning } from "@/components/form";
import GlobalSmallLoading from "@/components/global-small-loading";
import LoadingButton from "@/components/loading-button";
import { sleep } from "@/helpers";
import { getDeviceInfo } from "@/helpers/device";
import { graphQLErrorCode } from "@/helpers/format";
import { getClientOrigin, hasIdentity, setIdentity } from "@/helpers/identity";
import {
  addParamsToPath,
  assignPathWith,
  getParams,
  rawURLAssign,
} from "@/helpers/navigation";
import { gql, useMutation } from "@apollo/client";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SIGNUP = gql`
  mutation OrganizationSignup(
    $email: String!
    $phone: String
    $password: String!
    $organizationName: String!
    $originInput: OriginInput!
    $deviceInput: DeviceInput!
  ) {
    OrganizationSignup(
      email: $email
      phone: $phone
      password: $password
      organizationName: $organizationName
      originInput: $originInput
      deviceInput: $deviceInput
    ) {
      id
      email
      role
      accessToken
      organization {
        id
      }
    }
  }
`;

export default function SignUp() {
  const { t } = useTranslation("organization");
  const navigate = useNavigate();

  const params = getParams();
  const [formData, setFormData] = useState({
    organizationName: "",
    email: params.get("email") || "",
    phone: params.get("phone") || "",
    password: "",
    terms_of_service: "",
  });
  const [termsOfService, setTermsOfService] = useState(false);
  const [mutationSignUp, { data, loading, error }] = useMutation(SIGNUP);

  const withPhone = !!params.get("phone");

  useEffect(() => {
    if (data) {
      setIdentity({
        id: data.OrganizationSignup.id,
        accessToken: data.OrganizationSignup.accessToken,
        role: data.OrganizationSignup.role,
        organizationID: data.OrganizationSignup.organization.id,
      });

      (async () => {
        while (hasIdentity() !== true) await sleep(500);
        assignPathWith(navigate, "/organization/zones/create?primary=true");
      })();
    }
  }, [data]);

  if (data) {
    // we show nothing because
    // it'll redirect the page directly
    return <GlobalSmallLoading />;
  }

  const handleTermsOfService: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    handleChange(event);
    setTermsOfService(event.currentTarget.checked);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    // we recover tall the data that could have been transmitted
    // from the landings if the person was on a landing
    const originInput = getClientOrigin();
    const deviceInput = getDeviceInfo();

    mutationSignUp({
      variables: {
        organizationName: formData.organizationName,
        email: formData.email,
        phone: formData.phone || null,
        password: formData.password,
        deviceInput,
        originInput,
      },
    });
  };

  const formCompleted =
    formData.organizationName.length > 0 &&
    formData.email.length > 0 &&
    formData.password.length > 0 &&
    termsOfService === true;

  const disabled = !formCompleted || loading;

  let compError: JSX.Element = <></>;
  let hasCompError: boolean = false;
  if (error) {
    if (
      graphQLErrorCode(error, "EMAIL_ALREADY_TAKEN") ||
      graphQLErrorCode(error, "EMAIL_ALREADY_TAKEN_OTHER_ORGANIZATION")
    ) {
      hasCompError = true;

      const params = {
        email: formData.email,
        method: "email",
      };

      const path = addParamsToPath("/authentication/sign-in", params);

      compError = (
        <Trans t={t} i18nKey="sign-up.email-already-taken">
          text <a href={path}>text</a>
        </Trans>
      );
    }
  }

  // this is a temporary message to display a discount when clicking on ""
  const clientOrigin = getClientOrigin();
  let warning = "";
  if (clientOrigin.originOffer === "advanced-application") {
    warning =
      "Congratulations! You're eligible for a free trial of 1 month. It'll be activated automatically once you reach more than 5 active workers.";
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="bg-white p-3 rounded-xl mt-10 shadow-md"
    >
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("sign-up.title")}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          {hasCompError ? (
            <FormErrorComponent error={compError} sx={{ mb: 3 }} />
          ) : (
            <FormError error={error} sx={{ mb: 3 }} />
          )}
          {warning ? <FormWarning message={warning} sx={{ mb: 3 }} /> : <></>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                type="email"
                label={t("sign-up.email")}
                name="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <PhoneField
                fullWidth
                id="phone"
                type="phone"
                label={t("sign-up.phone")}
                name="phone"
                value={formData.phone}
                onChange={(phone) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    phone,
                  }));
                }}
                autoComplete="phone"
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                value={formData.password}
                onChange={handleChange}
                label={t("sign-up.password")}
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="organization"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleChange}
                required
                fullWidth
                id="organizationName"
                label={t("sign-up.organization-name")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="agreed"
                    name="terms_of_service"
                    color="primary"
                    onChange={handleTermsOfService}
                  />
                }
                label={
                  <Trans t={t} i18nKey="sign-up.terms-checkbox">
                    text{" "}
                    <a target="_blank" href="/legal/terms-of-service">
                      terms
                    </a>{" "}
                    text{" "}
                    <a target="_blank" href="/legal/privacy-statement">
                      privacy
                    </a>
                  </Trans>
                }
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={loading}
            disabled={disabled}
            onClick={handleSubmit}
            text={t("sign-up.submit")}
            fullWidth
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                style={{ textTransform: "unset" }}
                onClick={() => {
                  const params = {
                    email: formData.email,
                    method: "email",
                  };

                  const path = addParamsToPath(
                    "/authentication/sign-in",
                    params
                  );
                  rawURLAssign(path);
                }}
              >
                {t("sign-up.already-have-account")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
