import { SensitiveData } from "@/components/sensitive-data";
import { assignPathWith } from "@/helpers/navigation";
import { getDurationFromString } from "@/helpers/time";
import { Report, SessionsMetric } from "@/interfaces";
import { tableContainerSx, tableHeadSx, tableSx } from "@/theme";
import { gql, useQuery } from "@apollo/client";
import BarChartIcon from "@mui/icons-material/BarChart";
import { IconButton, TableCell, TableContainer, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Name } from "../members/list";
import { getSearchDate } from "../members/metrics";

export function ActionMetrics({ member, searchDate = "" }) {
  const navigate = useNavigate();
  const { t } = useTranslation("organization");

  let searchParams = "";
  // If it's custom, for now we just don't move it over
  // Because it'll not work since we don't have the exact dates
  if (searchDate && searchDate !== "custom") {
    searchParams = `?date_selector=${searchDate}`;
  }

  return (
    <Tooltip
      title={t("members.list.team-members-metrics-button")}
      placement="top"
    >
      <IconButton
        color="primary"
        aria-label={t("members.list.team-members-metrics-button")}
        onClick={() => {
          assignPathWith(
            navigate,
            `/organization/dashboard/members/${member.id}/metrics${searchParams}`
          );
        }}
      >
        <BarChartIcon />
      </IconButton>
    </Tooltip>
  );
}

export function List({ members, zone, dateRange, setReports, reports }) {
  const { t } = useTranslation("organization");

  let nothingToShow = <></>;

  if (members == null || members.length === 0 || reports.length === 0) {
    nothingToShow = (
      <React.Fragment>
        <TableRow key="0">
          <TableCell className="m-auto text-center" colSpan={6}>
            {t("members.list.nothing-to-show")}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Table key="0" size="small" sx={tableSx}>
        <TableHead>
          <TableRow key="0">
            <TableCell sx={tableHeadSx}>{t("members.list.name")}</TableCell>
            <TableCell sx={tableHeadSx}>
              {t("members.metrics.average-work-per-day")}
            </TableCell>
            <TableCell sx={tableHeadSx}>
              {t("members.metrics.time-worked")}
            </TableCell>
            <TableCell sx={tableHeadSx}>
              {t("members.metrics.longest-work-day")}
            </TableCell>
            <TableCell sx={tableHeadSx}>
              {t("members.metrics.shortest-work-day")}
            </TableCell>
            <TableCell sx={tableHeadSx}>{t("members.list.action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nothingToShow}
          {members.map((member, idx) => (
            <TableMemberMetrics
              key={idx}
              zone={zone}
              member={member}
              dateRange={dateRange}
              setReports={setReports}
            />
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

const GET_SESSIONS_METRIC = gql`
  query GetSessionsMetric(
    $startDate: Time!
    $endDate: Time!
    $memberId: UUID!
    $zoneId: UUID
  ) {
    GetSessionsMetric(
      startDate: $startDate
      endDate: $endDate
      memberId: $memberId
      zoneId: $zoneId
    ) {
      totalSessions
      totalDuration
      averageDuration
      highestDuration
      lowestDuration
      earliestStartedAt
      latestStartedAt
      earliestEndedAt
      latestEndedAt
      firstStartedAt
      lastEndedAt
      averageDailyDuration
      highestDailyDuration
      lowestDailyDuration
    }
  }
`;

export function TableMemberMetrics({ member, zone, dateRange, setReports }) {
  const { t } = useTranslation("organization");

  const searchDate = getSearchDate();

  const variables = {
    startDate: dateRange.start,
    endDate: dateRange.end,
    memberId: member.id,
  };

  if (zone.id) {
    variables["zoneId"] = zone.id;
  }

  const { data } = useQuery(GET_SESSIONS_METRIC, {
    variables,
  });

  let averageWorkPerDay = "";
  let timeWorked = "";
  let longestWorkDay = "";
  let shortestWorkDay = "";
  let totalSessions = 0;

  if (data) {
    const sessionsMetric: SessionsMetric = data.GetSessionsMetric;
    averageWorkPerDay = sessionsMetric.averageDailyDuration;
    timeWorked = sessionsMetric.totalDuration;
    longestWorkDay = sessionsMetric.highestDailyDuration;
    shortestWorkDay = sessionsMetric.lowestDailyDuration;
    totalSessions = sessionsMetric.totalSessions;
  }

  React.useEffect(() => {
    if (data) {
      const sessionsMetric: SessionsMetric = data.GetSessionsMetric;
      if (sessionsMetric.totalSessions === 0) {
        return;
      }
      // NOTE: this system replace the data received of the member each time we choose a new data
      // and it basically rebuild the reports
      setReports((prevData) => {
        // Filter out the existing item with the same member.id
        const filteredData = prevData.filter(
          (item) => item.member.id !== member.id
        );
        const payload: Report = { member, sessionsMetric };
        // Add the new payload
        return [...filteredData, payload];
      });
    }
  }, [data]);

  if (totalSessions === 0) {
    return <></>;
  }

  return (
    <React.Fragment>
      <TableRow key={member.id}>
        <TableCell>
          <SensitiveData fakeType="name">
            <Name member={member} />
          </SensitiveData>
        </TableCell>
        <TableCell>
          {averageWorkPerDay
            ? getDurationFromString(averageWorkPerDay, true, t)
            : "-"}
        </TableCell>
        <TableCell>
          {timeWorked ? getDurationFromString(timeWorked, true, t) : "-"}
        </TableCell>
        <TableCell>
          {longestWorkDay
            ? getDurationFromString(longestWorkDay, true, t)
            : "-"}
        </TableCell>
        <TableCell>
          {shortestWorkDay
            ? getDurationFromString(shortestWorkDay, true, t)
            : "-"}
        </TableCell>
        <TableCell>
          <ActionMetrics member={member} searchDate={searchDate} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ReportsList({
  members,
  zone,
  dateRange,
  setReports,
  reports,
}) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List
          members={members}
          zone={zone}
          dateRange={dateRange}
          setReports={setReports}
          reports={reports}
        />
      </TableContainer>
    </React.Fragment>
  );
}
