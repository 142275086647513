import AstroWondering from "@/components/astro-wondering";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function NotFound({ title, extra = "" }) {
  return (
    <Container
      component="main"
      maxWidth="xs"
      className="bg-white p-3 rounded-xl mt-10 shadow-md"
    >
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <QuestionMarkIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <AstroWondering />
        {extra}
      </Box>
    </Container>
  );
}
