import { Copyright } from "@/components/copyright";
import GlobalSmallLoading from "@/components/global-small-loading";
import { assignPathWith, getParams } from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { gql, useQuery } from "@apollo/client";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MY_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      name
      slug
    }
  }
`;

export default function MemberIndex() {
  const { t } = useTranslation("organization");
  const navigate = useNavigate();

  const [primary, setPrimary] = useState<boolean>(false);
  const [organization, setOrganization] = useState({
    id: "",
    slug: "",
    name: "",
  });
  const myOrganization = useQuery(MY_ORGANIZATION);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  // For now de prefer to force the primary zone to be fixed
  // There's still the option to go to the moving zone.
  useEffect(() => {
    if (primary) {
      assignPathWith(
        navigate,
        `/organization/zones/create/fixed?primary=${primary}`
      );
    }
  }, [primary]);

  if (myOrganization.loading) return <GlobalSmallLoading />;
  if (myOrganization.data) {
    if (organization.id === "")
      setOrganization(myOrganization.data.MyOrganization);
  }
  if (myOrganization.error) return <NotFound error={myOrganization.error} />;

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="xs"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <QrCodeIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
            {primary
              ? t("zones.create.title-primary", { name: organization.name })
              : t("zones.create.title", { name: organization.name })}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    assignPathWith(
                      navigate,
                      `/organization/zones/create/fixed?primary=${primary}`
                    );
                  }}
                  size="large"
                  sx={{ mt: 2, p: 3, textTransform: "none" }}
                >
                  <LocationOnIcon />
                  <Trans t={t} i18nKey="zones.create.fixed-button">
                    {" "}
                    <br />{" "}
                  </Trans>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    assignPathWith(
                      navigate,
                      `/organization/zones/create/moving?primary=${primary}`
                    );
                  }}
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 2, p: 3, textTransform: "none" }}
                >
                  <TimeToLeaveIcon />
                  <Trans t={t} i18nKey="zones.create.moving-button">
                    {" "}
                    <br />{" "}
                  </Trans>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
