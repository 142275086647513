// import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GlobalSmallLoading from "./components/global-small-loading";
import { sleep } from "./helpers";
import { Permission, Resource, permitAccess } from "./helpers/access";
import { eventBusEmit, eventBusReceive } from "./helpers/event-bus";
import {
  clearIdentity,
  getLanguage,
  getRole,
  isGuest,
  setIdentity,
  setLanguage,
  withDashboardAccess,
  withoutDashboardAccess,
} from "./helpers/identity";
import { currentRoute, rawURLReplace } from "./helpers/navigation";
import PageError from "./pages/error";

const PING_ATTEMPTS = 3;
const PING_FREQUENCY = 200;
export const FORCE_SUBSCRIPTION_FROM_ACTIVE_MEMBERS = 5;

const PING = gql`
  query Ping {
    Ping
  }
`;

const GET_MYSELF = gql`
  query GetMyself {
    GetMyself {
      id
      role
      password
      displayLanguage
      organization {
        id
        subscriptionType
      }
    }
  }
`;

const GET_ORGANIZATION_METRIC = gql`
  query GetOrganizationMetric {
    GetOrganizationMetric {
      totalActiveMembers
      totalEvents
    }
  }
`;

export default function Preflight({ children }) {
  const { t, i18n } = useTranslation("misc");
  // before sending anything we set a base setLanguage
  // this will also set i18n.language and allow us to have at all time
  // within our system and communication with the API.
  if (!getLanguage()) setLanguage("system", i18n);

  const getPing = useQuery(PING, {
    notifyOnNetworkStatusChange: true,
  });
  const [crash, setCrash] = useState<number>(0);

  const getGetMyself = useQuery(GET_MYSELF, {
    // for now we only get the myself member to retrieve
    // the language if not defined, in the future we might want
    // to go further and define window.myself
    skip: isGuest(),
  });
  const getOrganizationMetric = useQuery(GET_ORGANIZATION_METRIC, {
    // since we don't get any ID it's better
    // to just avoid caching on this one
    fetchPolicy: "no-cache",
    skip: isGuest() || withoutDashboardAccess(getRole()),
  });

  // sometimes apollo crashes with "Load failed"
  // which has nothing to do with the network or server itself
  useEffect(() => {
    if (getPing.error?.message === "Failed to fetch") {
      setCrash(crash + 1);

      (async () => {
        await sleep(PING_FREQUENCY);
        if (crash < PING_ATTEMPTS) getPing.refetch();
      })();
    }
  }, [getPing]);

  useEffect(() => {
    if (getGetMyself.data) {
      const myself = getGetMyself.data.GetMyself;
      if (
        myself.organization.subscriptionType &&
        myself.organization.subscriptionType !== "free-forever" &&
        permitAccess({
          role: getRole(),
          resource: Resource.BILLING,
          permission: Permission.UPDATE,
        })
      ) {
        // We have to use event buses to change the menu
        // Because it's too complicated to get down the layouts
        eventBusReceive("menu-ready", () => {
          eventBusEmit({ type: "menu-items", payload: "show-billing" });
        });
        eventBusEmit({ type: "menu-items", payload: "show-billing" });
      } else {
        eventBusReceive("menu-ready", () => {
          eventBusEmit({ type: "menu-items", payload: "show-upgrade" });
        });
        eventBusEmit({ type: "menu-items", payload: "show-upgrade" });
      }
      if (myself.displayLanguage) {
        setLanguage(myself.displayLanguage, i18n);
      }
    }
  }, [getGetMyself.data]);

  useEffect(() => {
    if (getOrganizationMetric.data) {
      if (getOrganizationMetric.data.GetOrganizationMetric.totalEvents >= 2) {
        eventBusReceive("menu-ready", () => {
          eventBusEmit({ type: "menu-items", payload: "show-reports" });
        });
        eventBusEmit({ type: "menu-items", payload: "show-reports" });
      }
    }
  }, [getOrganizationMetric.data]);

  if (getGetMyself.data) {
    const myself = getGetMyself.data.GetMyself;
    // We refresh the identity stored on each preflight
    // In case roles or permissions change
    setIdentity(myself);

    // specific to dashboard access members
    if (withDashboardAccess(myself.role)) {
      if (!myself.password && !currentRoute("/myself/password")) {
        rawURLReplace("/myself/password");
        // we show nothing because
        // it'll redirect the page directly
        return <GlobalSmallLoading />;
      }

      if (
        !myself.organization.subscriptionType &&
        myself.role !== "TECH" &&
        !currentRoute("/organization/subscription") &&
        getOrganizationMetric?.data?.GetOrganizationMetric?.totalActiveMembers >
          FORCE_SUBSCRIPTION_FROM_ACTIVE_MEMBERS
      ) {
        rawURLReplace("/organization/subscription");
        // we show nothing because
        // it'll redirect the page directly
        return <GlobalSmallLoading />;
      }
    }
  }

  if (getPing.loading || (getPing.error && crash <= PING_ATTEMPTS)) {
    return <GlobalSmallLoading />;
  }

  // sometimes, ping can be faulty
  // it happens on organization sign-up sometimes and will break the flow
  // a tolerance of 3 seems fair on network issues.
  if (crash >= PING_ATTEMPTS) {
    clearIdentity();
    return <PageError error={t("error.ping")} />;
  }

  return <Suspense>{children}</Suspense>;
}
