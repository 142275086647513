import AstroWondering from "@/components/astro-wondering";
import { Copyright } from "@/components/copyright";
import { capitalizeFirst } from "@/helpers/format";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
export default function PageError({ error, extra = <></> }) {
  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="xs"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <PriorityHighIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {capitalizeFirst(error)}
          </Typography>
          <AstroWondering />
        </Box>
        {extra}
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
